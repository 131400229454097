import React from "react"
import { graphql } from "gatsby"

// Components
import Layout from "../components/layout"
import Seo from "../components/seo"
import Banner from "../components/banner"
import Numbers from "../components/numbers"
import AboutUs from "../components/aboutUs"
import Manifesto from "../containers/About/manifesto"

const AboutPage = ({ data }) => {
  const {
    ogTitle,
    ogDescription,
    bannerTitle,
    bannerSubtitle,
    bannerImage,
    achievementCreationDate,
    achievementCreationDateTitle,
    achievementQuotesGenerated,
    achievementQuotesGeneratedTitle,
    achievementServingEnterprises,
    achievementServingEnterprisesTitle,
    aboutTitle,
    aboutDetails,
    manifestoTitle,
    manifestoDetails,
  } = data.about

  const numberData = {
    achievementCreationDate,
    achievementCreationDateTitle,
    achievementQuotesGenerated,
    achievementQuotesGeneratedTitle,
    achievementServingEnterprises,
    achievementServingEnterprisesTitle,
  }

  const aboutData = {
    aboutTitle,
    aboutDetails,
  }

  const manifestoData = {
    manifestoTitle,
    manifestoDetails,
  }

  return (
    <Layout>
      <Seo title={ogTitle || "About Us"} description={ogDescription} />
      <Banner title={bannerTitle} slogan={bannerSubtitle} image={bannerImage} />
      <Numbers data={numberData} />
      <AboutUs data={aboutData} />
      <Manifesto data={manifestoData} />
    </Layout>
  )
}

export const query = graphql`
  query AboutQuery {
    about: contentfulAboutPage {
      ogTitle
      ogDescription
      bannerTitle
      bannerSubtitle
      bannerImage {
        title
        gatsbyImageData(width: 400, quality: 100, placeholder: BLURRED)
      }
      achievementCreationDate
      achievementCreationDateTitle
      achievementQuotesGenerated
      achievementQuotesGeneratedTitle
      achievementServingEnterprises
      achievementServingEnterprisesTitle
      aboutTitle
      aboutDetails {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            __typename
            title
            gatsbyImageData(width: 650, quality: 100, placeholder: BLURRED)
          }
        }
      }
      manifestoTitle
      manifestoDetails {
        raw
      }
    }
  }
`

export default AboutPage
