import React from "react"

import RichtextRenderer from "../../../components/RichtextRenderer"
import * as styles from "./index.module.scss"

const Manifesto = ({ data }) => {
  return (
    <div
      className={`${styles.manifestoContainer} mt-8 pt-10 pb-8 xl:pb-36 px-4 xl:px-0`}
    >
      <div className="max-w-6xl mx-auto">
        <h3 className="mb-6 text-center lg:text-left">{data.manifestoTitle}</h3>
        <RichtextRenderer data={data.manifestoDetails} />
      </div>
    </div>
  )
}

export default Manifesto
