import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { BLOCKS, INLINES, MARKS } from "@contentful/rich-text-types"

import RichtextRenderer from "../RichtextRenderer"
import QuoteView from "../quoteView"
import * as styles from "./index.module.scss"

const options = {
  renderNode: {
    [BLOCKS.HEADING_1]: (node, children) => <h1>{children}</h1>,

    [BLOCKS.HEADING_2]: (node, children) => <h2>{children}</h2>,

    [BLOCKS.HEADING_3]: (node, children) => (
      <h3 className="mt-8 mb-2">{children}</h3>
    ),

    [BLOCKS.HEADING_4]: (node, children) => <h4>{children}</h4>,

    [BLOCKS.HEADING_5]: (node, children) => <h5>{children}</h5>,

    [BLOCKS.HEADING_6]: (node, children) => <h6>{children}</h6>,

    [BLOCKS.PARAGRAPH]: (node, children) => <p className="my-4">{children}</p>,

    [BLOCKS.UL_LIST]: (node, children) => (
      <ul className="my-5 ml-4 md:ml-10 flex flex-col">{children}</ul>
    ),

    [BLOCKS.OL_LIST]: (node, children) => <ol>{children}</ol>,

    [BLOCKS.LIST_ITEM]: (node, children) => {
      return (
        <li className="bulletItem">
          <span className="flex-1">{children}</span>
        </li>
      )
    },

    [BLOCKS.HR]: () => <hr />,

    [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
      const image = getImage(node?.data?.target?.gatsbyImageData)
      const altText = node?.data?.target?.title

      return (
        <div className="flex items-center justify-center">
          <GatsbyImage
            className="w-10/12 md:w-1/2 h-auto my-8 rounded-lg shadow-xl"
            image={image}
            loading="lazy"
            alt={altText}
          />
        </div>
      )
    },

    [BLOCKS.EMBEDDED_ENTRY]: (node, children) => {
      const { author, remarks } = node?.data?.target
      const quoteData = { author, remarks }

      return <QuoteView smallLogo data={quoteData} />
    },

    [INLINES.HYPERLINK]: node => {
      const { content, data } = node

      return (
        <a
          className="cursor-pointer text-blue-500 hover:underline hover:text-blue-700"
          href={data.uri}
          target="_blank"
          rel="noreferrer"
        >
          {content[0].value}
        </a>
      )
    },
  },

  renderMark: {
    [MARKS.UNDERLINE]: text => <u>{text}</u>,
    [MARKS.BOLD]: text => <strong>{text}</strong>,
    [MARKS.ITALIC]: text => <i>{text}</i>,
  },
}

const AboutUs = ({ data }) => {
  return (
    <div className="mt-12  px-4 xl:px-0">
      <div className="max-w-6xl mx-auto">
        <h3 className="mb-4 text-center lg:text-left">{data.aboutTitle}</h3>
        <div className={`${styles.borderedContainer} blog-text`}>
          <RichtextRenderer data={data.aboutDetails} options={options} />
        </div>
      </div>
    </div>
  )
}

export default AboutUs
